<template>
  <div class="page-other">
    <div class="page-other-title">
      <div class="back" @click="() => this.$router.back()"><i class="el-icon-back"></i></div>
      <div class="text">指令工具</div>
    </div>
    <div class="page-other-content">
      <Card title="发送指令" class="w350">
        <el-form class="ml-3" :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="120rem" label-position="left">
          <el-form-item label="指令类型" prop="cmd_type">
            <el-select v-model="ruleForm.cmd_type" placeholder="请选择指令类型">
              <el-option :label="item.label" :value="item.value" v-for="item in options" :key="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="指令名称" prop="cmd">
            <el-input v-model="ruleForm.cmd" placeholder="请输入指令名称" style="width: 240rem"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
          <el-form-item label="返回内容">
            <el-input type="textarea" v-model="back" disabled resize="none" :autosize="{ minRows: 4 }"></el-input>
          </el-form-item>
        </el-form>
      </Card>
    </div>
  </div>
</template>
<script>
import Card from "@/components/common/Card.vue";
import { system_cmd } from '@/api'
export default {
  components: { Card },
  data() {
    return {
      options: [
        { label: 'mysql', value: 'mysql' },
        { label: 'redis', value: 'redis' },
      ],
      back: '',
      ruleForm: {
        cmd_type: '',
        cmd: '',
      },
      rules: {
        cmd_type: [
          { required: true, message: '请选择指令类型', trigger: 'change' }
        ],
        cmd: [
          { required: true, message: '请输入指令名称', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          system_cmd(this.ruleForm).then(res =>{
            this.back = res
          })
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>
<style lang="scss" scoped></style>
